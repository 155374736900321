export const WITHDRAWALS_DISABLED_REASONS = {
  EMAIL_CHANGE: {
    value: 'EMAIL_CHANGE',
    label: 'email change',
  },
  PASSWORD_CHANGE: {
    value: 'PASSWORD_CHANGE',
    label: 'password change',
  },
  TWO_FA_REMOVAL: {
    value: 'TWO_FA_REMOVAL',
    label: '2FA reset',
  },
};

export const ADDRESS_TAGS = {
  MEMO: 'Memo',
  DESTINATION_TAG: 'Destination tag',
  PAYMENT_ID: 'Payment ID',
};
