import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TwitterIcon from 'react-icons/lib/fa/twitter';
import FacebookIcon from 'react-icons/lib/fa/facebook';
import LinkedInIcon from 'react-icons/lib/fa/linkedin';
import RedditIcon from 'react-icons/lib/fa/reddit';
import MediumIcon from 'react-icons/lib/fa/medium';
import config from 'frontend-utils/lib/config';
import RokkexLogo from '../../../assets/images/logo.svg';
import ExternalLink from '../ExternalLink/ExternalLink';
import styles from './Footer.scss';

export const Footer = ({ className }) => (
  <footer className={classnames(styles.root, className)}>
    <div className={styles.wrapper}>
      <div className={styles.logoContainer}>
        <RokkexLogo className={styles.logo} />
        <span className={styles.copyright}>
          © {new Date().getFullYear()} ROKKEX OÜ
        </span>
      </div>
      <div className={styles.linksContainer}>
        <div className={styles.col}>
          <span className={styles.colName}>
            Support
          </span>
          <ExternalLink
            href={`${config.supportUrl}/hc/en-us/articles/360007790420-Minimums-and-fees`}
            className={styles.link}
          >
            Fees
          </ExternalLink>
          <ExternalLink
            href={`${config.supportUrl}`}
            className={styles.link}
          >
            Knowledge Base
          </ExternalLink>
        </div>

        <div className={styles.col}>
          <span className={styles.colName}>
            Law
          </span>
          <ExternalLink
            href={`${config.companyUrl}/privacy-policy`}
            className={styles.link}
          >
            Privacy Policy
          </ExternalLink>
          <ExternalLink
            href={`${config.companyUrl}/terms-and-conditions`}
            className={styles.link}
          >
            Terms & Conditions
          </ExternalLink>
          <ExternalLink
            href="https://blog.rokkex.com/rules-of-aml-tf-isa"
            className={styles.link}
          >
            AML Policy
          </ExternalLink>
        </div>

        <div className={styles.col}>
          <span className={styles.colName}>
            Downloads
          </span>
          <ExternalLink
            href="https://blog.rokkex.com/rkx_wp"
            className={styles.link}
          >
            White Paper
          </ExternalLink>
          <ExternalLink
            href="https://blog.rokkex.com/rkx_lp"
            className={styles.link}
          >
            Light Paper
          </ExternalLink>
        </div>

        <div className={styles.col}>
          <span className={styles.colName}>
            Contact Us
          </span>
          <a href="mailto:info@rokkex.com" className={styles.link}>
            info@rokkex.com
          </a>
          <a href="mailto:support@rokkex.com" className={styles.link}>
            support@rokkex.com
          </a>
          <a href="mailto:legal@rokkex.com" className={styles.link}>
            legal@rokkex.com
          </a>
        </div>

        <div className={styles.col}>
          <span className={styles.colName}>
            Communities
          </span>
          <div className={styles.iconsContainer}>
            <ExternalLink href="https://twitter.com/ROKKEX_com" className={styles.iconContainer}>
              <TwitterIcon className={styles.icon} />
            </ExternalLink>
            <ExternalLink href="https://www.facebook.com/rokkex" className={styles.iconContainer}>
              <FacebookIcon className={styles.icon} />
            </ExternalLink>
            <ExternalLink href="https://www.linkedin.com/company/rokkex" className={styles.iconContainer}>
              <LinkedInIcon className={styles.icon} />
            </ExternalLink>
            <ExternalLink href="https://www.reddit.com/r/Rokkex" className={styles.iconContainer}>
              <RedditIcon className={styles.icon} />
            </ExternalLink>
            <ExternalLink href="https://medium.com/@rokkex" className={styles.iconContainer}>
              <MediumIcon className={styles.icon} />
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: null,
};

export default Footer;
