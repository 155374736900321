import React from 'react';
import PropTypes from 'prop-types';
import { LinkPreloader } from 'web-utils/lib/components';
import { ROUTES } from '../../../Landing/Landing.const';

export const NavbarLink = ({
  path,
  regeneratePath,
  external,
  className,
  children,
}) => {
  if (external) {
    if (regeneratePath) {
      return (
        <button
          type="button"
          className={className}
          onClick={() => window.open(path(), '_blank')}
        >
          {children}
        </button>
      );
    }

    return (
      <a
        href={typeof path === 'string' ? path : path()}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <LinkPreloader
      to={typeof path === 'string' ? path : path()}
      routes={ROUTES}
      className={className}
    >
      {children}
    </LinkPreloader>
  );
};

NavbarLink.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
  regeneratePath: PropTypes.bool,
  className: PropTypes.string,
};

NavbarLink.defaultProps = {
  external: false,
  regeneratePath: false,
  className: null,
};

export default NavbarLink;
