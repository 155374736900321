import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { PrivateRoute, ErrorBoundary } from 'web-utils/lib/components';
import config from 'frontend-utils/lib/config';
import { withAuthContainer } from 'frontend-utils/lib/containers';
import Page404 from '../Page404/Page404.loadable';
import Maintenance from '../Maintenance/Maintenance.loadable';
import Home from '../Home/Home.loadable';
import OrderBook from '../OrderBook/OrderBook.loadable';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy.loadable';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions.loadable';
import SystemStatus from '../SystemStatus/SystemStatus.loadable';
import PublicAdvancedTradingView from '../PublicAdvancedTradingView/PublicAdvancedTradingView.loadable';
import ProviderWindow from '../KYC/ProviderWindow/ProviderWindow.loadable';
import Landing from '../Landing/Landing';
import ScrollToTop from './ScrollToTop';
import AuthProvider from './AuthProviderWrapper/AuthProviderWrapper';
import CookiePolicy from './CookiePolicy/CookiePolicy.loadable';

const PrivateRouteWithAuth = withAuthContainer(PrivateRoute);
const showMaintenance = process.env.RKX_MAINTENANCE && localStorage.getItem('RKX_IGNORE_MAINTENANCE') !== 'ignore';

export default function App() {
  useEffect(() => {
    if (config.googleSiteKey) {
      loadReCaptcha(config.googleSiteKey);
    }
  });

  return (
    <ErrorBoundary>
      <Router>
        <ScrollToTop>
          <AuthProvider>
            {showMaintenance ? <Maintenance /> : (
              <Switch>
                <PrivateRouteWithAuth exact path="/" redirectPathname="/home" component={Landing} />
                <PrivateRouteWithAuth path="/acc" redirectPathname="/home" component={Landing} />
                <Route path="/home" component={Home} />
                <Route path="/kyc" component={ProviderWindow} />
                <Route path="/status" component={SystemStatus} />
                <Route path="/order-book" component={OrderBook} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms-and-conditions" component={TermsAndConditions} />
                <Route path="/trade-advanced/:market?" component={PublicAdvancedTradingView} />
                <Route
                  path="/ppom"
                  component={() => {
                    window.location.href = 'https://blog.rokkex.com/ppom';
                    return null;
                  }}
                />
                <Route
                  path="/rkx_lp"
                  component={() => {
                    window.location.href = 'https://blog.rokkex.com/rkx_lp';
                    return null;
                  }}
                />
                <Route
                  path="/rkx_wp"
                  component={() => {
                    window.location.href = 'https://blog.rokkex.com/rkx_wp';
                    return null;
                  }}
                />
                <Route component={Page404} />
              </Switch>
            )}
          </AuthProvider>
        </ScrollToTop>
      </Router>
      <CookiePolicy />
    </ErrorBoundary>
  );
}
