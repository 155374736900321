import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'web-utils/lib/components';
import { AuthProvider } from 'frontend-utils/lib/containers';

export const AuthProviderWrapper = ({ children, history }) => (
  <AuthProvider
    onNetworkErrorLogout={() => history.push('/home')}
    onInitialAuthenticatedErrorCheck={() => history.push('/home')}
    renderLoader={() => (
      <Spinner
        position="middle"
        message="Loading your experience..."
      />
    )}
  >
    {children}
  </AuthProvider>
);

AuthProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(AuthProviderWrapper);
