import GiftIcon from 'react-icons/lib/fa/gift';
import MarketIcon from 'react-icons/lib/md/timeline';
import BriefcaseIcon from 'react-icons/lib/fa/briefcase';
import ShuffleIcon from 'react-icons/lib/io/ios-shuffle-strong';
import WalletIcon from 'react-icons/lib/md/account-balance-wallet';
import config from 'frontend-utils/lib/config';
import { ACCOUNT_TYPES } from 'frontend-utils/lib/constants';
import { getSupportUrl } from 'web-utils/lib/utils/general';

export const DISPLAYED_NOTIFICATIONS_COUNT = 3;
const IS_DEVELOPMENT = process.env.RKX_ENV === 'development';

export const MENU_ITEMS = [
  {
    size: 21,
    icon: MarketIcon,
    title: 'Market',
    path: '/acc/market',
  },
  {
    size: 20,
    icon: WalletIcon,
    title: 'Wallet',
    path: '/acc/wallet',
    submenus: [
      {
        title: 'Balance',
        path: '/acc/wallet',
      },
      {
        title: 'Deposit',
        path: '/acc/deposit',
      },
      {
        title: 'Withdraw',
        path: '/acc/withdrawals',
      },
      // {
      //   title: 'Ledger',
      //   path: '/acc/wallet/ledger',
      // },
    ],
  },
  {
    size: 21,
    icon: ShuffleIcon,
    title: 'Trade',
    path: '/acc/trade',
    submenus: [
      {
        title: 'Basic Trade',
        path: '/acc/trade',
      },
      {
        title: 'Advanced Trade',
        path: '/acc/trade-advanced',
      },
      {
        title: 'Open Orders',
        path: '/acc/trade-orders',
      },
      {
        title: 'Trade History',
        path: '/acc/trade-history',
      },
    ],
  },
  {
    size: 18,
    icon: BriefcaseIcon,
    title: 'STO',
    path: '/acc/sto/dashboard',
    submenus: [
      {
        title: 'Dashboard',
        path: '/acc/sto/dashboard',
      },
    ],
  },
  {
    size: 21,
    icon: GiftIcon,
    title: 'Rewards',
    path: '/acc/shop/referrals',
    submenus: [
      {
        title: 'Referrals',
        path: '/acc/shop/referrals',
      },
      {
        title: 'Shop',
        path: '/acc/shop',
      },
      {
        title: 'Orders',
        path: '/acc/shop/orders',
      },
    ],
  },
];

export const PROFILE_MENU_ITEMS = [
  {
    title: 'Account Overview',
    path: '/acc/details',
  },
  {
    title: ({ accountType }) => (accountType === ACCOUNT_TYPES.BUSINESS ? 'Business Profile' : 'Profile'),
    path: '/acc/profile',
  },
  {
    title: 'Notifications',
    path: '/acc/notifications',
  },
  {
    title: 'Security',
    path: '/acc/security',
  },
  {
    title: 'Support',
    external: true,
    path: ({ id, email, firstName }) => getSupportUrl({ id, email, firstName }),
  },
  {
    title: 'Newsflash',
    path: '/acc/newsflash',
    display: () => IS_DEVELOPMENT,
  },
  {
    title: 'Admin Client',
    external: true,
    path: config.adminClientUrl,
    display: ({ roleId }) => !!roleId || false,
  },
];
