import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CloseIcon from 'react-icons/lib/md/close';
import formatDistance from 'date-fns/distance_in_words_to_now';
import notificationsStyles from '../../../Notifications/NotificationsList/NotificationsList.scss';
import styles from './NotificationItem.scss';

export class NotificationItem extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    onClear: PropTypes.func.isRequired,
  }

  render() {
    const {
      id,
      type,
      message,
      createdAt,
      onClear,
    } = this.props;

    return (
      <div className={classnames(styles.root, notificationsStyles[type])}>
        <span className={styles.message}>{message}</span>
        <div className={styles.timeContainer}>
          <CloseIcon
            size={20}
            className={styles.closeIcon}
            onClick={() => onClear(id)}
          />
          <span className={styles.time}>
            {formatDistance(createdAt, { addSuffix: true }).replace('about ', '')}
          </span>
        </div>
      </div>
    );
  }
}

export default NotificationItem;
