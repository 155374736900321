export const LS_LAST_USED_MARKET_KEY = '@RKX_LAST_USED_MARKET';

export const ORDER_EVENTS = {
  ORDER_CANCELED: 'ORDER_CANCELED',
  ORDER_CREATED: 'ORDER_CREATED',
  ORDER_OPENED: 'ORDER_OPENED',
};

export const TRADE_EVENTS = {
  TRADE_CREATED: 'TRADE_CREATED',
};
