import differenceInDays from 'date-fns/difference_in_days';
import { LEVELS } from './Security.const';

export function getLastPasswordUpdateSecurityLevel(updatedAt) {
  if (updatedAt && differenceInDays(Date.now(), updatedAt) <= 60) {
    return { ...LEVELS.MAXIMUM, points: 10 };
  }

  return { ...LEVELS.WEAK, points: 0 };
}

export function get2FASecurityLevel(authenticators) {
  const authenticatorsLength = authenticators.length;

  if (authenticatorsLength >= 2) {
    return { ...LEVELS.MAXIMUM, points: 40 };
  }

  if (authenticatorsLength === 1) {
    return { ...LEVELS.STRONG, points: 20 };
  }

  return { ...LEVELS.WEAK, points: 0 };
}

export function getWhitelistedIPSecurityLevel(whitelistedIPAddresses) {
  if (whitelistedIPAddresses.isEnabled) {
    return { ...LEVELS.MAXIMUM, points: 20 };
  }

  return { ...LEVELS.WEAK, points: 0 };
}

export function getSessionLengthSecurityLevel(sessionLength) {
  const ONE_HOUR = 3600000;

  if (sessionLength <= (ONE_HOUR / 4)) {
    return { ...LEVELS.MAXIMUM, points: 10 };
  }

  if (sessionLength <= (ONE_HOUR / 2)) {
    return { ...LEVELS.STRONG, points: 8 };
  }

  if (sessionLength <= ONE_HOUR) {
    return { ...LEVELS.AVERAGE, points: 6 };
  }

  if (sessionLength <= (ONE_HOUR * 5)) {
    return { ...LEVELS.WEAK, points: 4 };
  }

  return { ...LEVELS.WEAK, points: 2 };
}

export function getLockdownSettingsUpdateSecurityLevel(lockdownSettings) {
  let points;
  const ONE_HOUR = 3600000;

  if (!lockdownSettings) {
    return { ...LEVELS.WEAK, points: 0 };
  }

  const { time, attempts } = lockdownSettings;

  if (attempts <= 5) {
    points = 10;
  } else if (attempts <= 10) {
    points = 8;
  } else if (attempts <= 15) {
    points = 6;
  } else if (attempts <= 20) {
    points = 4;
  } else {
    points = 2;
  }

  if (time >= (ONE_HOUR * 24)) {
    points += 10;
  } else if (time >= (ONE_HOUR * 8)) {
    points += 8;
  } else if (time >= (ONE_HOUR * 4)) {
    points += 6;
  } else if (time >= (ONE_HOUR * 2)) {
    points += 4;
  } else {
    points += 2;
  }

  if (points <= 8) {
    return { ...LEVELS.WEAK, points };
  }

  if (points <= 16) {
    return { ...LEVELS.AVERAGE, points };
  }

  return { ...LEVELS.MAXIMUM, points };
}

export function getOverallSecurityLevel({
  authenticators,
  sessionLength,
  passwordUpdatedAt,
  lockdownSettings,
  whitelistedIPAddresses,
}) {
  const twoFASecurityLevel = get2FASecurityLevel(authenticators);
  const sessionLengthSecurityLevel = getSessionLengthSecurityLevel(sessionLength);
  const lastPasswordUpdateSecurityLevel = getLastPasswordUpdateSecurityLevel(passwordUpdatedAt);
  const lockdownSettingsSecurityLevel = getLockdownSettingsUpdateSecurityLevel(lockdownSettings);
  const whitelistedIPAddressesSecurityLevel = getWhitelistedIPSecurityLevel(whitelistedIPAddresses);
  const levels = [
    whitelistedIPAddressesSecurityLevel,
    twoFASecurityLevel,
    sessionLengthSecurityLevel,
    lastPasswordUpdateSecurityLevel,
    lockdownSettingsSecurityLevel,
  ];
  const points = levels.reduce((accumulator, level) => accumulator + level.points, 0);

  if (points === 100) {
    return { ...LEVELS.MAXIMUM, points };
  }

  if (points < 100 && points > 60) {
    return { ...LEVELS.STRONG, points };
  }

  if (points <= 60 && points > 20) {
    return { ...LEVELS.AVERAGE, points };
  }

  return { ...LEVELS.WEAK, points };
}
