import Wallet from '../Wallet/Wallet.loadable';
import WalletDeposits from '../Wallet/WalletDeposits/WalletDeposits.loadable';
import CreditCardDeposit from '../Wallet/CreditCardDeposit/CreditCardDeposit.loadable';
import WalletLedger from '../Wallet/WalletLedger/WalletLedger.loadable';
import WalletWithdrawals from '../Wallet/WalletWithdrawals/WalletWithdrawals.loadable';
import WalletWithdrawalAddress from '../Wallet/WalletWithdrawalAddress/WalletWithdrawalAddress.loadable';
import WalletWithdrawalAccount from '../Wallet/WalletWithdrawalAccount/WalletWithdrawalAccount.loadable';
import WalletWithdrawalCancelation from '../Wallet/WalletWithdrawalCancelation/WalletWithdrawalCancelation.loadable';
import WalletWithdrawalConfirmation from '../Wallet/WalletWithdrawalConfirmation/WalletWithdrawalConfirmation.loadable';
import WalletAccountCancelation from '../Wallet/WalletAccountCancelation/WalletAccountCancelation.loadable';
import WalletAccountConfirmation from '../Wallet/WalletAccountConfirmation/WalletAccountConfirmation.loadable';
import WalletAddressCancelation from '../Wallet/WalletAddressCancelation/WalletAddressCancelation.loadable';
import WalletAddressConfirmation from '../Wallet/WalletAddressConfirmation/WalletAddressConfirmation.loadable';
import MyAccount from '../MyAccount/MyAccount.loadable';
import Market from '../Market/Market.loadable';
import Trade from '../Trade/Trade.loadable';
import TradeAdvanced from '../Trade/TradeAdvanced/TradeAdvanced.loadable';
import TradeHistory from '../Trade/TradeHistory/TradeHistory.loadable';
import TradeOpenOrders from '../Trade/TradeOpenOrders/TradeOpenOrders.loadable';
import Security from '../Security/Security.loadable';
import Profile from '../Profile/Profile.loadable';
import Notifications from '../Notifications/Notifications.loadable';
import NotificationPreferences from '../Notifications/NotificationPreferences/NotificationPreferences.loadable';
import Newsflash from '../Newsflash/Newsflash.loadable';
import KYC from '../KYC/KYC.loadable';
import Business from '../Business/Business.loadable';
import STOSendFunds from '../STO/STOSendFunds/STOSendFunds.loadable';
import STODashboard from '../STO/STODashboard/STODashboard.loadable';
import Referrals from '../Referrals/Referrals.loadable';
import Shop from '../Shop/Shop.loadable';
import ShopProduct from '../Shop/Product/Product.loadable';
import ShopCheckout from '../Shop/Checkout/Checkout.loadable';
import ShopOrders from '../Shop/Orders/Orders.loadable';

export const PATHNAMES_WITH_TRADES_HISTORY = ['/acc/trade/:market?', '/acc/trade-history'];
export const PATHNAMES_WITH_OPEN_ORDERS = ['/acc/trade/:market?', '/acc/trade-advanced/:market?', '/acc/trade-orders'];
export const PATHNAMES_WITH_BALANCES = [
  '/acc/kyc/:step',
  '/acc/details',
  '/acc/wallet',
  '/acc/deposit/:symbol?',
  '/acc/wallet/ledger',
  '/acc/withdrawals/:symbol?',
];

export const ROUTES = [
  { path: '/acc/wallet', exact: true, Component: Wallet },
  { path: '/acc/payments-gateway', Component: CreditCardDeposit },
  { path: '/acc/deposit/:symbol?', Component: WalletDeposits },
  { path: '/acc/withdrawals/:symbol?', Component: WalletWithdrawals },
  { path: '/acc/withdrawal/cancel/:id', Component: WalletWithdrawalCancelation },
  { path: '/acc/withdrawal/confirm/:id', Component: WalletWithdrawalConfirmation },
  { path: '/acc/withdrawal-address', exact: true, Component: WalletWithdrawalAddress },
  { path: '/acc/withdrawal-account', exact: true, Component: WalletWithdrawalAccount },
  { path: '/acc/withdrawal-account/cancel/:id', exact: true, Component: WalletAccountCancelation },
  { path: '/acc/withdrawal-account/confirm/:id', exact: true, Component: WalletAccountConfirmation },
  { path: '/acc/withdrawal-address/cancel/:id', exact: true, Component: WalletAddressCancelation },
  { path: '/acc/withdrawal-address/confirm/:id', exact: true, Component: WalletAddressConfirmation },
  { path: '/acc/wallet/ledger', Component: WalletLedger },
  { path: '/acc/details', Component: MyAccount },
  { path: '/acc/market', Component: Market },
  { path: '/acc/trade/:market?', Component: Trade },
  { path: '/acc/trade-advanced/:market?', Component: TradeAdvanced },
  { path: '/acc/trade-orders', Component: TradeOpenOrders },
  { path: '/acc/trade-history', Component: TradeHistory },
  { path: '/acc/profile', Component: Profile },
  { path: '/acc/security', Component: Security },
  { path: '/acc/kyc/:step', Component: KYC },
  { path: '/acc/newsflash', Component: Newsflash },
  { path: '/acc/notifications', exact: true, Component: Notifications },
  { path: '/acc/notifications/preferences', exact: true, Component: NotificationPreferences },
  { path: '/acc/business/:step?', Component: Business },
  { path: '/acc/sto/dashboard', exact: true, Component: STODashboard },
  { path: '/acc/sto/send-funds', exact: true, Component: STOSendFunds },
  { path: '/acc/shop/referrals', exact: true, Component: Referrals },
  { path: '/acc/shop', exact: true, Component: Shop },
  { path: '/acc/shop/product/:id', Component: ShopProduct },
  { path: '/acc/shop/checkout', Component: ShopCheckout },
  { path: '/acc/shop/orders', Component: ShopOrders },
];
