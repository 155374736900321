import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ExternalLink.scss';

export class ExternalLink extends PureComponent {
  static propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    target: PropTypes.string,
  }

  static defaultProps = {
    className: null,
    target: null,
  }

  render() {
    const {
      href,
      children,
      className,
      target,
    } = this.props;

    return (
      <a
        target={target || '_blank'}
        rel="noopener noreferrer"
        href={href}
        className={classnames(styles.root, className)}
      >
        {children}
      </a>
    );
  }
}

export default ExternalLink;
