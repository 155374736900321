export const LEVELS = {
  WEAK: {
    key: 'weak',
    type: 'danger',
    color: '#d64848',
  },
  AVERAGE: {
    key: 'average',
    type: 'warning',
    color: '#fe9705',
  },
  STRONG: {
    key: 'strong',
    type: 'success',
    color: '#3ac430',
  },
  MAXIMUM: {
    key: 'maximum',
    type: 'success',
    color: '#3ac430',
  },
};
