import React, { PureComponent } from 'react';
import flow from 'lodash/fp/flow';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import UserIcon from 'react-icons/lib/io/ios-contact';
import BellIcon from 'react-icons/lib/md/notifications';
import ChevronDownIcon from 'react-icons/lib/md/keyboard-arrow-down';
import { KYC_DECLINED_REASONS } from 'frontend-utils/lib/constants';
import {
  withAuthContainer,
  withWalletContainer,
  withSocketsContainer,
  withNotificationsContainer,
} from 'frontend-utils/lib/containers';
import RokkexLogo from '../../../assets/images/logo.svg';
import { getOverallSecurityLevel } from '../../Security/Security.utils';
import NavbarLink from './NavbarLink/NavbarLink';
import NotificationItem from './NotificationItem/NotificationItem';
import { getNotificationsMessage, getActiveLinkTitle } from './Navbar.utils';
import { MENU_ITEMS, PROFILE_MENU_ITEMS, DISPLAYED_NOTIFICATIONS_COUNT } from './Navbar.const';
import styles from './Navbar.scss';

class Navbar extends PureComponent {
  static propTypes = {
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    kycStatus: PropTypes.string,
    businessKycStatus: PropTypes.string,
    passwordNotSet: PropTypes.bool,
    kycDeclinedReason: PropTypes.oneOf([...Object.keys(KYC_DECLINED_REASONS)]),
    roleId: PropTypes.string,
    accountType: PropTypes.string.isRequired,
    gsl: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }).isRequired,
    whitelistedIPAddresses: PropTypes.shape({
      isEnabled: PropTypes.bool,
      ipAddresses: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    withdrawalsDisabledUntil: PropTypes.string,
    withdrawalsDisabledReason: PropTypes.string,
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    sessionLength: PropTypes.number.isRequired,
    passwordUpdatedAt: PropTypes.number.isRequired,
    authenticators: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    lockdownSettings: PropTypes.shape({}).isRequired,
    socketConnected: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    clearNotification: PropTypes.func.isRequired,
    clearAllNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }

  static defaultProps = {
    firstName: null,
    middleName: null,
    lastName: null,
    kycStatus: null,
    passwordNotSet: false,
    businessKycStatus: null,
    kycDeclinedReason: null,
    roleId: null,
    withdrawalsDisabledUntil: null,
    withdrawalsDisabledReason: null,
  }

  static getDerivedStateFromProps = (props) => {
    const activeMenuItem = getActiveLinkTitle(props);

    return { activeMenuItem };
  }

  state = {
    activeMenuItem: getActiveLinkTitle(this.props),
  }

  onLogout = () => {
    const { logout, history } = this.props;

    logout({ onLogout: () => history.push('/home') });
  }

  render() {
    const { activeMenuItem } = this.state;
    const {
      id,
      email,
      gsl,
      accountType,
      firstName,
      middleName,
      lastName,
      roleId,
      kycStatus,
      kycDeclinedReason,
      businessKycStatus,
      authenticators,
      sessionLength,
      lockdownSettings,
      passwordNotSet,
      passwordUpdatedAt,
      withdrawalsDisabledUntil,
      withdrawalsDisabledReason,
      whitelistedIPAddresses,
      notifications,
      socketConnected,
      clearNotification,
      clearAllNotifications,
    } = this.props;
    const unreadNotifications = notifications.filter(notification => !notification.seen);
    const securityLevel = getOverallSecurityLevel({
      authenticators,
      sessionLength,
      lockdownSettings,
      whitelistedIPAddresses,
      passwordUpdatedAt: passwordNotSet ? null : passwordUpdatedAt,
    });
    const notificationMessage = getNotificationsMessage({
      id,
      email,
      firstName,
      accountType,
      kycStatus,
      businessKycStatus,
      kycDeclinedReason,
      withdrawalsDisabledUntil,
      withdrawalsDisabledReason,
    });
    const enteredName = firstName || middleName || lastName;
    const fullName = enteredName
      ? `${firstName ? `${firstName} ` : ''}${middleName ? `${middleName} ` : ''}${lastName || ''}`
      : '-';

    return (
      <div className={notificationMessage ? styles.root : null}>
        <div className={styles.wrapper}>
          {notificationMessage && (
            <div style={{ backgroundColor: notificationMessage.color }} className={styles.notificationMessage}>
              {notificationMessage.text}
            </div>
          )}
          <div className={styles.navbar}>
            <div className={styles.col}>
              <Link to="/">
                <RokkexLogo />
              </Link>
            </div>

            <div className={styles.col}>
              <div className={styles.menu}>
                {MENU_ITEMS.map(({
                  title,
                  size,
                  icon: Icon,
                  external,
                  submenus = [],
                  path = '',
                }) => (
                  <div key={title} className={styles.menuItem}>
                    <NavbarLink
                      path={path}
                      external={external}
                      className={classnames([
                        styles.menuItemLink,
                        title === activeMenuItem && styles.active,
                      ])}
                    >
                      <Icon size={size} className={styles.menuItemIcon} />
                      <div className={styles.menuItemtext}>{title}</div>
                    </NavbarLink>
                    {!!submenus.length && (
                      <div className={styles.submenuContainer}>
                        {submenus.map(({ title, path }) => (
                          <NavbarLink
                            key={path}
                            path={path}
                            external={external}
                            className={styles.submenuLink}
                          >
                            {title}
                          </NavbarLink>
                        ))}
                      </div>
                    )}
                  </div>
                  ))}
              </div>
            </div>

            <div className={styles.col}>
              <div className={styles.notifications}>
                <div className={styles.bellContainer}>
                  <BellIcon size={30} />
                  {unreadNotifications.length ? (
                    <div className={styles.unreadBubble}>
                      {unreadNotifications.length > 9 ? '9+' : unreadNotifications.length}
                    </div>
                  ) : null}
                </div>

                <div className={styles.notificationsSubmenuContainer}>
                  <div className={styles.triangle} />
                  <div className={styles.notificationSubmenuContent}>
                    <div className={styles.notificationsSubmenuHeader}>
                      <span>
                        <BellIcon size={18} />
                        Notifications
                      </span>
                      <button type="button" onClick={clearAllNotifications}>
                        Clear all
                      </button>
                    </div>
                    {unreadNotifications.slice(0, DISPLAYED_NOTIFICATIONS_COUNT).map(notification => (
                      <NotificationItem
                        key={notification.id}
                        {...notification}
                        onClear={clearNotification}
                      />
                    ))}
                    {unreadNotifications.length === 0 && (
                      <span className={styles.noNewNotifications}>
                        You have no new notifications
                      </span>
                    )}
                    <div className={styles.viewAllNotifications}>
                      <Link to="/acc/notifications">
                        View {unreadNotifications.length === 0 ? 'older' : 'all'}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.profileMenu}>
                <div className={styles.profileDetails}>
                  <UserIcon size={30} />
                  <span
                    className={classnames(
                      styles.socketStatus,
                      socketConnected && styles.socketConnected,
                      !socketConnected && styles.socketDisonnected
                    )}
                  />
                  <div className={styles.nameAndTierContainer}>
                    <div
                      className={styles.name}
                      title={fullName}
                    >
                      {fullName}
                    </div>
                    {!gsl.enabled && (
                      <div
                        className={styles.tier}
                        style={{ color: securityLevel.color }}
                      >
                        Security level: <span className={styles.securityLevel}>{securityLevel.key}</span>
                      </div>
                    )}
                  </div>
                </div>
                <ChevronDownIcon color="#8a94a6" />
                <div className={styles.submenuContainer}>
                  {PROFILE_MENU_ITEMS.map(({
                    title,
                    external,
                    path,
                    display = () => true,
                  }) => (
                    display({ roleId }) ? (
                      <NavbarLink
                        key={path}
                        regeneratePath={typeof path === 'function'}
                        path={typeof path === 'string' ? path : (() => path({ id, email, firstName }))}
                        external={external}
                        className={styles.submenuLink}
                      >
                        {typeof title === 'function' ? title(this.props) : title}
                      </NavbarLink>
                    ) : null
                  ))}
                  <button
                    type="button"
                    onClick={this.onLogout}
                    onKeyDown={this.onLogout}
                    className={classnames([styles.submenuLink, styles.submenuLinkButton])}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default flow(
  withAuthContainer,
  withWalletContainer,
  withSocketsContainer,
  withNotificationsContainer,
  withRouter
)(Navbar);
