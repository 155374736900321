import React from 'react';
import 'regenerator-runtime/runtime';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './components/App/App';
import 'web-utils/lib/styles/index.scss';
import 'rc-slider/assets/index.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

if (process.env.RKX_ENV) {
  Sentry.init({
    environment: process.env.RKX_ENV,
    dsn: 'https://a9233ce61b2545b3818a463c5cc99fe6@sentry.io/1281831',
  });
}

render(<App />, document.getElementById('rokkex-root'));
