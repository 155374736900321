import React, { Fragment, Component } from 'react';
import { Route, Redirect, withRouter, matchPath } from 'react-router-dom';
import flow from 'lodash/fp/flow';
import throttle from 'lodash/fp/throttle';
import PropTypes from 'prop-types';
import { notificationAPI } from 'frontend-utils/lib/utils/notification';
import { KYC_STATUSES, KYC_EVENTS, ACCOUNT_TYPES, MARKET_EVENTS } from 'frontend-utils/lib/constants';
import {
  withAuthContainer,
  AssetProvider,
  NotificationsProvider,
  withNotificationsContainer,
  TradeProvider,
  withTradeContainer,
  WalletProvider,
  withWalletContainer,
  MarketProvider,
  withMarketContainer,
  SocketsProvider,
  withSocketsContainer,
} from 'frontend-utils/lib/containers';
import { ORDER_EVENTS, TRADE_EVENTS } from '../Trade/Trade.const';
import Footer from '../common/Footer/Footer';
import Navbar from './Navbar/Navbar';
import UpdateProfileModal from './UpdateProfileModal/UpdateProfileModal.loadable';
import SessionExpiringModal from './SessionExpiringModal/SessionExpiringModal.loadable';
import {
  ROUTES,
  PATHNAMES_WITH_BALANCES,
  PATHNAMES_WITH_OPEN_ORDERS,
  PATHNAMES_WITH_TRADES_HISTORY,
} from './Landing.const';

const PUBLIC_CHANNEL = 'PUBLIC';
const PRIVATE_CHANNEL = 'PRIVATE';

export class Landing extends Component {
  static propTypes = {
    id: PropTypes.string,
    sessionLength: PropTypes.number,
    socket: PropTypes.shape({}).isRequired,
    logout: PropTypes.func.isRequired,
    addNewNotification: PropTypes.func.isRequired,
    checkIfAuthenticated: PropTypes.func.isRequired,
    loadOpenOrders: PropTypes.func.isRequired,
    loadTradeHistory: PropTypes.func.isRequired,
    loadWalletBalances: PropTypes.func.isRequired,
    handleMarketsPricesChange: PropTypes.func.isRequired,
    setUserDataToState: PropTypes.func.isRequired,
    businessKycStatus: PropTypes.oneOf(Object.values(KYC_STATUSES)),
    accountType: PropTypes.oneOf(Object.values(ACCOUNT_TYPES)).isRequired,
    lastOpenOrdersQuery: PropTypes.shape({}),
    lastTradeHistoryQuery: PropTypes.shape({}),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    id: '',
    sessionLength: 15 * 60 * 1000,
    lastOpenOrdersQuery: null,
    lastTradeHistoryQuery: null,
    businessKycStatus: null,
  };

  componentDidUpdate({ location: { pathname: prevPathname } }) {
    const {
      loadWalletBalances,
      location: { pathname },
    } = this.props;

    if (pathname !== prevPathname) {
      loadWalletBalances();
    }

    if (!this.isSocketListenersAdded) {
      this.initializeSocketListeners();
    }
  }

  componentWillUnmount() {
    const { socket } = this.props;

    if (socket.connected) {
      socket.disconnect();
    }
  }

  isSocketListenersAdded = false;

  initializeSocketListeners = () => {
    const { id, socket } = this.props;

    if (!id) {
      return;
    }

    if (!socket.hasListeners(PUBLIC_CHANNEL)) {
      socket.on(PUBLIC_CHANNEL, this.handlePublicSocketEvent);
    }

    if (!socket.hasListeners(PRIVATE_CHANNEL)) {
      socket.on(PRIVATE_CHANNEL, this.handlePrivateSocketEvent);
    }

    this.isSocketListenersAdded = true;
  }

  doPathsContainPathname = (paths = []) => {
    const { location } = this.props;

    return !!paths.find(pathname => !!matchPath(
      location.pathname,
      pathname
    ));
  }

  handlePublicSocketEvent = ({ event, data }) => {
    const { handleMarketsPricesChange } = this.props;

    if (event === MARKET_EVENTS.PRICE_CHANGE) {
      handleMarketsPricesChange(data);
    }
  }

  handlePrivateSocketEvent = ({
    message,
    event,
    visible,
    type = 'info',
    ...rest
  }) => {
    if (KYC_EVENTS[event]) {
      this.handleKYCStatusChange(rest.data.status);
    }

    if (ORDER_EVENTS[event] || TRADE_EVENTS[event]) {
      this.handleOrderStatusChange();
    }

    if (visible) {
      notificationAPI.show({ type, message });
      this.handleNewNotification({ type, message, ...rest });
    }
  }

  handleKYCStatusChange = (status) => {
    const { setUserDataToState } = this.props;

    setUserDataToState({ kycStatus: status });
  }

  handleOrderStatusChange = () => {
    const {
      loadOpenOrders,
      loadTradeHistory,
      loadWalletBalances,
      lastOpenOrdersQuery,
      lastTradeHistoryQuery,
    } = this.props;

    if (this.doPathsContainPathname(PATHNAMES_WITH_OPEN_ORDERS)) {
      loadOpenOrders(lastOpenOrdersQuery, { changeLoadingState: false });
    }

    if (this.doPathsContainPathname(PATHNAMES_WITH_TRADES_HISTORY)) {
      loadTradeHistory(lastTradeHistoryQuery, { changeLoadingState: false });
    }

    if (this.doPathsContainPathname(PATHNAMES_WITH_BALANCES)) {
      loadWalletBalances();
    }
  }

  handleOrderStatusChange = throttle(5000, this.handleOrderStatusChange, { leading: true });

  handleNewNotification = (notification) => {
    this.props.addNewNotification(notification);
  }

  render() {
    const {
      logout,
      checkIfAuthenticated,
      sessionLength,
      accountType,
      businessKycStatus,
    } = this.props;
    const redirectPath = (
      accountType === ACCOUNT_TYPES.BUSINESS
      && (!businessKycStatus || businessKycStatus === KYC_STATUSES.DENIED)
    )
      ? '/acc/business'
      : '/acc/market';

    return (
      <Fragment>
        <Navbar />
        <Route exact path="/" component={() => <Redirect to={redirectPath} />} />
        <Route exact path="/acc" component={() => <Redirect to={redirectPath} />} />
        {ROUTES.map(({ path, exact, Component }) => (
          <Route key={path} path={path} exact={exact} component={Component} />
        ))}
        <Footer />
        <UpdateProfileModal />
        <SessionExpiringModal
          logout={logout}
          sessionLength={sessionLength}
          checkIfAuthenticated={checkIfAuthenticated}
        />
      </Fragment>
    );
  }
}

const LandingWithContainers = flow(
  withRouter,
  withAuthContainer,
  withTradeContainer,
  withMarketContainer,
  withSocketsContainer,
  withWalletContainer,
  withNotificationsContainer
)(Landing);

export default ({ ...props }) => (
  <SocketsProvider>
    <AssetProvider>
      <WalletProvider>
        <MarketProvider>
          <TradeProvider>
            <NotificationsProvider>
              <LandingWithContainers {...props} />
            </NotificationsProvider>
          </TradeProvider>
        </MarketProvider>
      </WalletProvider>
    </AssetProvider>
  </SocketsProvider>
);
