import React from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import isFuture from 'date-fns/is_future';
import { getSupportUrl } from 'web-utils/lib/utils/general';
import { KYC_STATUSES, KYC_DECLINED_REASONS, ACCOUNT_TYPES } from 'frontend-utils/lib/constants';
import { WITHDRAWALS_DISABLED_REASONS } from '../../Wallet/WalletWithdrawals/WalletWithdrawals.const';
import styles from './Navbar.scss';

export function getNotificationsMessage({
  id,
  email,
  firstName,
  accountType,
  kycStatus,
  kycDeclinedReason,
  businessKycStatus,
  withdrawalsDisabledUntil,
  withdrawalsDisabledReason,
}) {
  if (accountType === ACCOUNT_TYPES.PERSONAL && !kycStatus) {
    return {
      color: '#d64848',
      text: (
        <span>
          Please complete our&nbsp;
          <Link to="/acc/kyc/verify-id" className={styles.link}>
            KYC
          </Link>
          &nbsp;process
        </span>
      ),
    };
  }

  if (accountType === ACCOUNT_TYPES.BUSINESS && !businessKycStatus) {
    return {
      color: '#d64848',
      text: (
        <span>
          Complete&nbsp;
          <Link to="/acc/business" className={styles.link}>
          business onboarding process
          </Link>
          &nbsp;to be able to deposit, trade & withdraw.
        </span>
      ),
    };
  }

  if (accountType === ACCOUNT_TYPES.BUSINESS && businessKycStatus === KYC_STATUSES.PENDING) {
    return {
      color: '#fe9705',
      text: 'Your business onboarding documents are pending for human approval.',
    };
  }

  if (accountType === ACCOUNT_TYPES.PERSONAL && kycStatus === KYC_STATUSES.PENDING) {
    return {
      color: '#fe9705',
      text: 'Your KYC is being reviewed',
    };
  }

  if (accountType === ACCOUNT_TYPES.BUSINESS && businessKycStatus === KYC_STATUSES.DENIED) {
    return {
      color: '#d64848',
      text: (
        <span>
          Your business onboarding was denied. Please contact our&nbsp;
          <button
            type="button"
            className={styles.link}
            onClick={() => window.open(getSupportUrl({ id, email, firstName }), '_blank')}
          >
            support
          </button>
          &nbsp;team.
        </span>
      ),
    };
  }

  if (
    (accountType === ACCOUNT_TYPES.PERSONAL && kycStatus === KYC_STATUSES.DENIED)
    || (accountType === ACCOUNT_TYPES.BUSINESS && businessKycStatus === KYC_STATUSES.DENIED)
  ) {
    const declinedReason = KYC_DECLINED_REASONS[kycDeclinedReason] || 'Your KYC was denied.';

    return {
      color: '#d64848',
      text: (
        <span>
          KYC status: {declinedReason} Please&nbsp;
          <Link to="/acc/kyc/verify-id" className={styles.link}>
            try again
          </Link>
        </span>
      ),
    };
  }

  if (withdrawalsDisabledUntil && isFuture(withdrawalsDisabledUntil)) {
    const suspensionReason = WITHDRAWALS_DISABLED_REASONS[withdrawalsDisabledReason];
    const suspensionReasonText = suspensionReason ? ` due to recent ${suspensionReason.label}` : '';

    return {
      color: '#d64848',
      text: `
        Your withdrawals are being suspended until
        ${format(withdrawalsDisabledUntil, 'YYYY-MM-DD HH:mm')}${suspensionReasonText}.
      `,
    };
  }

  return null;
}

export function getActiveLinkTitle({ history: { location: { pathname } } }) {
  const containsPath = path => pathname.indexOf(path) !== -1;

  if (containsPath('trade')) {
    return 'Trade';
  }

  if (containsPath('market')) {
    return 'Market';
  }

  if (containsPath('sto')) {
    return 'STO';
  }

  if (containsPath('shop')) {
    return 'Rewards';
  }

  return 'Wallet';
}
